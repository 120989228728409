import * as React from "react"
import { Link, navigate } from "gatsby"
import { AboutModuleStyles } from "./AboutModuleStyles"
import {StaticImage} from "gatsby-plugin-image";
import Button from "../Button/Button";


const AboutModule = ({ children, title, subTitle, price, enquire, imageSrc }) => {


    return (
        <>
            <AboutModuleStyles>
                {children ? (
                    children
                ) : (
                    <StaticImage
                        className="about__image"
                        imgClassName="about__image--content"
                        src="../../../static/0013_Jacob_Foley_2016Apr06_Blues_bw.jpg"
                        alt="About Jacob Foley Image"
                        layout="fullWidth"
                        placeholder="blurred"
                    />
                )}

                <div className="container">
                    <div className="about__content">
                        {title && (
                            <h1>
                                {title}
                                <span style={{ color: "var(--primary)" }}>.</span>
                            </h1>
                        )}
                        <div className="bio__content">
                            Jacob Foley serves as the Lead DevOps Engineer at <a href="https://projectliberty.io" target="_blank">Project Liberty</a>.
                            <br/><br/>
                            A results-driven technology innovator, Jacob demonstrates a keen ability to anticipate and adapt to emerging industry trends.
                            With a diverse skill set, he has expertise in automating video encoding and delivery for prominent production companies,
                            spearheading the launch of a new gTLD, managing the infrastructure behind advertisement delivery software processing over 1 million requests per minute,
                            safeguarding the financial data of high-profile individuals in Hollywood, constructing a safety-critical CI/CD pipeline,
                            leading the DevOps team supporting the first decentralized social network protocol, and founding his own lead-generation software company.
                            <br/><br/>
                            When he's not working, he enjoys tinkering with various micro-controller hardware projects,
                            python based AI frameworks, cooking, scuba diving, and taking his Goldendoodle to the park.

                        </div>

                    </div>
                </div>
                <div className="gradient"></div>
            </AboutModuleStyles>
            <span id="topContent"></span>
        </>
    )
}

export default AboutModule
