import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import AboutModule from "../components/AboutModule/AboutModule";

const contact = () => {
  return (
    <>
      <Seo title="Contact" />
      <Layout>
            <AboutModule title="About" />

      </Layout>
    </>
  )
}

export default contact
