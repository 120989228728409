import styled from "styled-components"

export const AboutModuleStyles = styled.section`
  height: 100vh;
  position: relative;
  padding: 30px var(--borderSpacing);

  .container {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .gradient,
  .about__image {
    position: absolute;
    //top: 0;
    //left: 0;
    right:0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .gradient {
    background: radial-gradient(
      at bottom left,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  }

  .about__content {
    position: relative;
    z-index: 2;
    min-height: 90vh;
    width: 100%;
    max-width: 85%;
        
    @media (min-width: 768px) {
      width: auto;
    }

    h1,
    h2 {
      text-shadow: var(--textShadow);
    }

    h1 {
      border-bottom: 2px solid rgba(255, 255, 255, 0.15);
      display: inline-block;
    }

    h2 {
      font-size: var(--h5);
      font-weight: 400;
    }

    h1,
    .price {
      margin-top: 0;
    }
      .bio__content{
          width: 50%;
          font-size:1.25em;
      }
  }

  .about__btns {
    display: flex;
    gap: var(--gap);
  }
`
